import { Injectable } from '@angular/core';

import { Computed, Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { UntilDestroy } from '@ngneat/until-destroy';
import { State } from '@ngxs/store';

import {
  TagInterface,
  TagsGameInterface,
  TagsInterface,
  TagsStateInterface,
} from './tags.interfaces';
import { Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Persistence()
@StateRepository()
@State({
  name: 'tags',
  defaults: {
    allTags: {},
    gameTags: {},
    gameId: 0,
  },
})
@Injectable({
  providedIn: 'any',
})
@UntilDestroy()
export class TagsState extends NgxsDataRepository<TagsStateInterface> {
  @Computed()
  get languages(): TagInterface[] {
    return this.snapshot?.allTags?.languages ?? [];
  }

  get ages(): TagInterface[] {
    return this.snapshot?.allTags?.ages ?? [];
  }

  get categories(): TagInterface[] {
    return this.snapshot?.allTags?.categories ?? [];
  }

  get tags(): TagsInterface {
    return this.snapshot.allTags;
  }

  get gameTags(): TagsGameInterface {
    return this.snapshot?.gameTags;
  }

  constructor(private httpClient: HttpClient) {
    super();
  }

  getAllTags(): Observable<TagsInterface> {
    return this.httpClient.get<TagsInterface>(`{api}/games/all-tags`).pipe(
      tap((tags: TagsInterface) => {
        this.ctx.setState({
          allTags: tags,
          gameTags: {},
          gameId: 0,
        });
      }),
    );
  }

  getGameTags(gameId: number): Observable<TagsGameInterface> {
    return this.httpClient.get<TagsGameInterface>(`{api}/games/${gameId}/tags`).pipe(
      tap((gameTags: TagsGameInterface) => {
        this.ctx.setState((state) => ({ ...state, gameTags, gameId }));
      }),
    );
  }

  setTags(gameId: number, tags: TagsGameInterface): Observable<TagsGameInterface> {
    return this.httpClient.post<TagsGameInterface>(`{api}/games/${gameId}/set-tags`, tags).pipe(
      tap((result) => {
        this.ctx.setState((state) => ({ ...state, gameTags: result }));
      }),
    );
  }
}
